import React from "react"
import Cards from "./Icons/Cards"
import Dashboard from "./Icons/Dashboard"
import Connections from "./Icons/Connections"
import Documents from "./Icons/Documents"
import Facilities from "./Icons/Facilities"
import Company from "./Icons/Company"
import Director from "./Icons/Director"
import Mandates from "./Icons/Mandates"
import Invoices from "./Icons/Invoices"
import Offers from "./Icons/Offers"
import Repayments from "./Icons/Repayments"
import Payees from "./Icons/Payees"
import Wallet from "./Icons/Wallet"
import Logout from "./Icons/Logout"
import Requests from "./Icons/Requests"
import Tag from "./Icons/Tag"
import Users from "./Icons/Users"

export const Sidebar_Icons = {
  Cards: <Cards />,
  Dashboard: <Dashboard />,
  Connections: <Connections />,
  Documents: <Documents />,
  Facilities: <Facilities />,
  Offers: <Offers />,
  Company: <Company />,
  Director: <Director />,
  Mandates: <Mandates />,
  Invoices: <Invoices />,
  Repayments: <Repayments />,
  Payees: <Payees />,
  Wallet: <Wallet />,
  Logout: <Logout />,
  Requests: <Requests />,
  Tag: <Tag />,
  Users: <Users />,
}
