export class URL {
  constructor(url) {
    this.url = url
  }
  toString() {
    return this.url
  }
}

const coreapp = process.env.REACT_APP_CORE_APP_URL || "http://localhost:3000"
const website = process.env.REACT_APP_WEBSITE_URL || "http://localhost:3001"

export const COREAPP = {
  signIn: new URL(`${coreapp}/users/sign_in`),
  signOut: new URL(`${coreapp}/users/sign_out`),
}

export const CLIENT_APP = {
  home: new URL(`${website}/`),
  signOut: new URL(`${website}/signout`),
}
