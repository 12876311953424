import React from "react"
const Tag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        d="m14.273 7.72-6-6a1.325 1.325 0 0 0-.94-.387H2.667c-.734 0-1.334.6-1.334 1.334v4.666c0 .367.147.7.394.947l6 6c.24.24.573.387.94.387.366 0 .7-.147.94-.394l4.666-4.666c.247-.24.394-.574.394-.94 0-.367-.154-.707-.394-.947Zm-5.606 5.62-6-6.007V2.667h4.666V2.66l6 6-4.666 4.68Z"
      />
      <path fill="currentColor" d="M5 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
    </svg>
  )
}

export default Tag
