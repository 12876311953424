import React from "react"
import SidebarLink from "./SidebarLink"
import { CLIENT_APP } from "./Routes"
import Logo from "./Logo"
import LogoutContainer from "./LogoutContainer"
import { Sidebar_Icons } from "./Styles/Sidebar/SidebarIcons"
import Logout from "./Styles/Sidebar/Icons/Logout"
import {
  StyledSidebar,
  SidebarHeader,
  SidebarBody,
  SidebarLinkGroup,
  SidebarLogo,
  SidebarLinkGroupTitle,
  LogoutButton,
  ErrorSidebar,
  SidebarFooter,
} from "./Styles/Sidebar/SidebarStyles"

export const Sidebar = ({
  applicationsOffers,
  companyUid,
  connections,
  director,
  documents,
  facilities,
  partners,
  members,
}) => {
  return (
    <StyledSidebar id="sidebar-wrapper" data-turbo="false">
      <SidebarHeader>
        <SidebarLogo>
          <Logo variant="light" />
        </SidebarLogo>
        <ErrorSidebar />
        <SidebarLink
          icon={Sidebar_Icons.Dashboard}
          label="Dashboard"
          href={`${CLIENT_APP.home}`}
        />
      </SidebarHeader>

      <SidebarBody>
        <SidebarLink
          icon={Sidebar_Icons.Dashboard}
          label="Dashboard"
          link={"/"}
        />
        <SidebarLinkGroup>
          <SidebarLinkGroupTitle>Account</SidebarLinkGroupTitle>
          <SidebarLink
            icon={Sidebar_Icons.Connections}
            label="Connections"
            href={connections}
          />
          <SidebarLink
            icon={Sidebar_Icons.Documents}
            label="Documents"
            href={documents}
          />
        </SidebarLinkGroup>
        <SidebarLinkGroup>
          <SidebarLinkGroupTitle>Company</SidebarLinkGroupTitle>
          <SidebarLink
            icon={Sidebar_Icons.Company}
            label="Company Details"
            href={`${CLIENT_APP.home}company`}
          />
          <SidebarLink
            icon={Sidebar_Icons.Director}
            label="Director Details"
            href={director}
          />
          <SidebarLink
            icon={Sidebar_Icons.Requests}
            label="Requests"
            href={`${CLIENT_APP.home}requests`}
          />
          <SidebarLink
            icon={Sidebar_Icons.Users}
            label="Members"
            href={members}
          />
          <SidebarLink
            icon={Sidebar_Icons.Tag}
            label="Partner Offers"
            href={partners}
          />
        </SidebarLinkGroup>
        {companyUid ? (
          <>
            {facilities.length > 0 && (
              <SidebarLinkGroup>
                <SidebarLinkGroupTitle>Facilities</SidebarLinkGroupTitle>
                {facilities.map(function (facility, index) {
                  return (
                    <SidebarLink
                      icon={Sidebar_Icons.Facilities}
                      label={companyUid + "/" + facility.application_uid}
                      key={index}
                      href={`/companies/${companyUid}/client_account/facilities/${facility.id}`}
                    />
                  )
                })}
              </SidebarLinkGroup>
            )}
            {applicationsOffers.length > 0 && (
              <SidebarLinkGroup>
                <SidebarLinkGroupTitle>Offers</SidebarLinkGroupTitle>
                {applicationsOffers.map(function (application, index) {
                  return (
                    <SidebarLink
                      icon={Sidebar_Icons.Offers}
                      label={companyUid + "/" + application.uid}
                      key={index}
                      href={application.link}
                    />
                  )
                })}
              </SidebarLinkGroup>
            )}
            {facilities.length > 0 && (
              <SidebarLinkGroup>
                <SidebarLinkGroupTitle>Manage</SidebarLinkGroupTitle>
                <SidebarLink
                  icon={Sidebar_Icons.Wallet}
                  label="Wallet"
                  href={`/companies/${companyUid}/client_account/wallet`}
                />
                <SidebarLink
                  icon={Sidebar_Icons.Cards}
                  label="Payment Cards"
                  href={`/companies/${companyUid}/client_account/cards`}
                />
                <SidebarLink
                  icon={Sidebar_Icons.Repayments}
                  label="Repayments"
                  href={`/companies/${companyUid}/repayments`}
                />
                <SidebarLink
                  icon={Sidebar_Icons.Mandates}
                  label="Mandates"
                  href={`/companies/${companyUid}/client_account/direct_debit_mandates`}
                />
                <SidebarLink
                  icon={Sidebar_Icons.Payees}
                  label="Payees"
                  href={`/companies/${companyUid}/client_account/beneficiaries`}
                />
                <SidebarLink
                  icon={Sidebar_Icons.Invoices}
                  label="Invoices"
                  href={`/companies/${companyUid}/client_account/invoices`}
                />
              </SidebarLinkGroup>
            )}
          </>
        ) : null}
      </SidebarBody>
      <SidebarFooter>
        <LogoutButton>
          <LogoutContainer>
            <Logout />
            <span>Logout</span>
          </LogoutContainer>
        </LogoutButton>
      </SidebarFooter>
    </StyledSidebar>
  )
}

export default Sidebar
